import React from "react";
import illustration from "../../../assets/img/illustration/kuantum.jpg";

function Workingprocess(props) {
  return (
    <section className="about-section about-illustration-img section-gap">
      <div className="container">
        <div className="illustration-img" style={{ marginTop: "20px" }}>
          <img style={{ borderRadius: "10px" }} src={illustration} alt="" />
        </div>
        <div className="row no-gutters justify-content-lg-end justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="about-text">
              <div className="section-title left-border mb-40">
                <span className="title-tag">Kuantum Nlp</span>
                {/* <h2 className="title">
                  KGF ve Ticari Kredi Finansmanı
                  <br />{" "}
                </h2> */}
              </div>
              <p
                className="mb-25"
                style={{ textIndent: "15px", textAlign: "justify" }}
              >
                Gelecekte olmasını istediğimiz durumlar, kendimizde görmek
                istediğimiz özellikler hakkında; hayaller, sesler ve duygularla
                oluşturulan bir düşünce biçimidir. Bu düşünce biçimi bizim
                hücresel bellek düzeyimizi, bilinçaltımızı ve tüm hayatı
                etkileyerek zincirleme reaksiyonlara neden olur.
              </p>

              <p style={{ textIndent: "15px", textAlign: "justify" }}>
                Kuantum Düşünce üst nitelikli bir düşünme biçimidir. Sıradan
                düşünce biçimleri kendisini tekrar eden, etkisiz ve sınırlı
                enerjilerdir. Değiştirme ve oluşturma güçleri yoktur. Daha çok
                kaygı, kuruntu, birbirini çağrıştıran zincirleme hayaller
                biçiminde akar. Oysa Kuantum Düşünce; derin düzeyde, atom altı
                alanda etkili olabilecek tarzda bir yaratıcı düşünme biçimidir.
                Özel bir bilinç düzeyine girerek, özel olarak kurgulanmış sözel
                ve imgesel oluşumları içerir. Bu düzeyde insan, kendi hayatının
                efendisi durumuna geçer. Kişi, varlığını sürdürmesini sağlayan
                ortak enerjiyle işbirliğine girdiğinde, tek bir "kişi" olmanın
                sınırlı olanaklarını aşar, "bütün"ün gücüne ulaşır. Bu durumda
                da gücünüz tabii ki bütünün gücüne eşit olacaktır.
              </p>
              <br></br>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Workingprocess;
