import React from "react";
import illustration from "../../../assets/img/illustration/yasam.jpg";

function Workingprocess(props) {
  return (
    <section className="about-section about-illustration-img section-gap">
      <div className="container">
        <div className="illustration-img" style={{marginTop:"20px"}}>
          <img style={{ borderRadius: "10px" }} src={illustration} alt="" />
        </div>
        <div className="row no-gutters justify-content-lg-end justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="about-text">
              <div className="section-title left-border mb-40">
                <span className="title-tag">Yaşam Koçluğu</span>
                {/* <h2 className="title">
                  KGF ve Ticari Kredi Finansmanı
                  <br />{" "}
                </h2> */}
              </div>
              <p
                className="mb-25"
                style={{ textIndent: "15px", textAlign: "justify" }}
              >
                Yaşam koçluğu, danışan kişinin potansiyelinin en üst limitlerine
                çıkmasını ve yaşam hedeflerine ulaşmasını sağlayan profesyonel
                bir rehberlik sistemidir. Yaşam koçluğu çalışmaları kapsamında
                kişisel gelişim ve kariyer planları üzerinde durulur. Yaşam
                koçu, gerekli eğitimleri tamamlamış, alanında uzman kişidir.
                Danışanıyla bire bir ilgilenerek kişinin kendi potansiyelini
                keşfetmesi, geliştirmesi, kişisel hedeflerini tanımlaması
                üzerine doğru soruları sorar ve danışanı harekete geçirmek için
                uygun stratejiler geliştirir.
              </p>

              <p style={{ textIndent: "15px", textAlign: "justify" }}>
              Yaşam Koçluğu Eğitim programlarına; yaşam koçluğuna ilgi duyan ve kendini bu alanda geliştirmek isteyen herkes başvurabilir. Her eğitim seviyesindeki insanlar, yaşam koçluğu programlarına başvurabilir.


              </p>
              <br></br>
             
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Workingprocess;
