import React, { Component, Fragment } from "react";
import Header from "../../layouts/Headertwo";
import Footer from "../../layouts/Footer";
import Content from "./Icerik";

class Service extends Component {
  render() {
    return (
      <Fragment>
        <Header />
        <Content />
        <section className="client-section">
          <div className="container"></div>
        </section>
        <Footer />
      </Fragment>
    );
  }
}

export default Service;
