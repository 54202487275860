import React from "react";
import { Route, Switch } from "react-router-dom";
import { HashRouter } from "react-router-dom";
// Preloader
import Preloader from "./components/layouts/Preloader";
// Pages
import BakanlikDestekleri from "./components/hizmetler/nlp/Nlp";
import Hometwo from "./components/Anasayfa/Hometwo";
import tetaHealing from "./components/hizmetler/tetaHealing/tetaHealing";
import Hakkimizda from "./components/Hakkimizda/Hakkimizda";
import AileDizinimi from "./components/hizmetler/aileDizinimi/AileDizinimi";
import Meditasyon from "./components/hizmetler/meditasyon/Meditasyon";
import EsmaTerapisi from "./components/hizmetler/esmaTerapisi/EsmaTerapisi";
import Biyoenerji from "./components/hizmetler/biyoenerji/Biyoenerji";
import YasamKoclugu from "./components/hizmetler/yasamKoclugu/YasamKoclugu";
import Hizmetler from "./components/Hizmetler/Hizmetler";
import Referanslar from "./components/Referanslar/Referanslar";
import Reglasyon from "./components/hizmetler/reglasyon/Reglasyon";
import Iletisim from "./components/İletisim/Iletisim";
import SikcaSorulanSorular from "./components/SikcaSorulanSorular/SikcaSorulanSorular";
import Akses from "./components/hizmetler/akses/Akses";
import Nlp from "./components/hizmetler/nlp/Nlp";
import cocukTerapisi from "./components/hizmetler/cocukTerapisi/CocukTerapisi";
import ruyaAnalizi from "./components/hizmetler/ruyaAnalizi/RuyaAnalizi";
import kuantumNlp from "./components/hizmetler/kuantumNlp/KuantumNlp";

export default () => {
  return (
    <HashRouter hashType={"noslash"}>
      <Preloader />
      <Switch>
        <Route exact path="/" component={Hometwo} />
        <Route path="/bakanlikDestekleri" component={BakanlikDestekleri} />
        <Route path="/tetaHealing" component={tetaHealing} />
        <Route path="/hakkimizda" component={Hakkimizda} />
        <Route path="/aileDizinimi" component={AileDizinimi} />
        <Route path="/meditasyon" component={Meditasyon} />
        <Route path="/esmaTerapisi" component={EsmaTerapisi} />
        <Route path="/biyoenerji" component={Biyoenerji} />
        <Route path="/yasamKoclugu" component={YasamKoclugu} />
        <Route path="/hizmetler" component={Hizmetler} />
        <Route path="/referanslar" component={Referanslar} />
        <Route path="/regresyon" component={Reglasyon} />
        <Route path="/iletisim" component={Iletisim} />
        <Route path="/access" component={Akses} />
        <Route path="/nlp" component={Nlp} />
        <Route path="/sikcaSorulanSorular" component={SikcaSorulanSorular} />
        <Route path="/cocukTerapisi" component={cocukTerapisi} />
        <Route path="/ruyaAnalizi" component={ruyaAnalizi} />
        <Route path="/kuantumNlp" component={kuantumNlp} />
      </Switch>
    </HashRouter>
  );
};
