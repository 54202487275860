import React from "react";
import illustration from "../../../assets/img/illustration/cocuk.jpg";

function Workingprocess(props) {
  return (
    <section className="about-section about-illustration-img section-gap">
      <div className="container">
        <div className="illustration-img" style={{ marginTop: "20px" }}>
          <img style={{ borderRadius: "10px" }} src={illustration} alt="" />
        </div>
        <div className="row no-gutters justify-content-lg-end justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="about-text">
              <div className="section-title left-border mb-40">
                <span className="title-tag">Çocuk Terapisi</span>
                {/* <h2 className="title">
                  KGF ve Ticari Kredi Finansmanı
                  <br />{" "}
                </h2> */}
              </div>
              <p
                className="mb-25"
                style={{ textIndent: "15px", textAlign: "justify" }}
              >
                Çocuk terapisi çocukların günlük hayatın içinde; evde, okulda ya
                da sosyal hayatlarındaki işlevselliklerinde bozulmalarına sebep
                olabilecek duygu durum ve davranış sorunlarının çözülmesinde
                kullanılan teknik ve metotları kapsayan sistemli,
                yapılandırılmış, profesyonel bir psikolojik destek sürecidir.
                Aynı zamanda hedefleri arasında çocuğun gelişimsel takibinin de
                yapılabilmesini, bu takip ile yaşına ve gelişim özelliklerine
                göre becerilerinin arttırılabilmesi, kendisini tanıma sürecinde
                iç görü kazanmasını, çocuğun duygusal açıdan güçlenebilmesini,
                ve çevresi ile sağlıklı iletişim kurmasına yardımcı olmasını
                sayabiliriz.
              </p>

              <p style={{ textIndent: "15px", textAlign: "justify" }}>
                Çocuk terapisinin başlangıcı, ailenin çocuğu getirme sebebi ne
                olursa olsun çocuk terapisindeki nihai amaç çocuğun benlik
                saygısının artması, benlik değerini yapılandırdığı alanların
                düzenlenmesi, çevresi ile ilişkilerinde iletişim becerilerini
                geliştirmesi ve duygu durum düzenlemesini öğrenmesi olarak
                söyleyebiliriz..
              </p>
              <br></br>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Workingprocess;
