import React from "react";

import illustration from "../../../assets/img/illustration/medi.jpg";

function Content(props) {
  return (
    <section className="about-section about-illustration-img section-gap">
      <div className="container">
        <div className="illustration-img"  style={{marginTop:"20px"}}>
          <img style={{ borderRadius: "10px" }} src={illustration} alt="" />
        </div>
        <div className="row no-gutters justify-content-lg-end justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="about-text">
              <div className="section-title left-border mb-40">
                <span className="title-tag">Meditasyon</span>
              </div>
              <p
                className="mb-25"
                style={{ textIndent: "15px", textAlign: "justify" }}
              >
                Çağımızda her şey hızla akıp gidiyor. Bir gün içerisinde onlarca
                kilometre yol yapıyor, trafik, gürültü, cep telefonu, mail,
                sosyal medya, internet, yüz yüze görüşmeler derken birçok
                uyarana maruz kalıyoruz. Bunların sonucunda tüm günümüz bir
                koşuşturmaca içinde geçiyor ve kendimize yeterli vakit
                ayıramadığımız için de stres bir türlü peşimizi bırakmıyor.
              </p>
              <p style={{ textIndent: "15px", textAlign: "justify" }}>
                Zihnimiz yoruldukça yoruluyor ve yılda bir iki kere yaptığımız
                kısa tatiller bu yorgunluğu gidermeye yetmiyor. Bu nedenle
                hepimizin zihnimizi boşaltmaya ve düşüncelerimizden uzaklaşmaya
                ihtiyacımız var. Zihni boşaltmanın spor yapmak, hobi edinmek
                gibi pek çok yolu var. Bunlardan biri de meditasyon. Peki
                meditasyon nedir? Meditasyon kişinin konsatrasyon yeteneğini
                geliştirerek düşüncelerinden uzaklaştığı ve beynini
                dinlendirdiği bir yöntem olarak tanımlanabilir.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Content;
