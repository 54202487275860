import React from 'react'
import { Link } from 'react-router-dom'

import img1 from '../../assets/img/illustration/yasam.jpg'
import img2 from '../../assets/img/illustration/aile.jpg'
import img3 from '../../assets/img/illustration/bio.jpg'
import img4 from '../../assets/img//illustration/medi.jpg'
import img5 from '../../assets/img/illustration/esma.jpg'
import img6 from '../../assets/img/illustration/reg.jpeg'
import img7 from '../../assets/img//illustration/nlp.jpg'
import img8 from '../../assets/img//illustration/teta.jpg'
import img9 from '../../assets/img//illustration/access.jpeg'
import img10 from '../../assets/img//illustration/cocuk.jpg'
import img11 from '../../assets/img//illustration/ruya.jpg'
import img12 from '../../assets/img//illustration/kuantum.jpg'


const featuresblock = [
    { img: img1, title: 'Yaşam Koçluğu', text:"" , link:"/yasamKoclugu"},
    { img: img2, title: 'Aile Dizimi', text: '' , link:"/aileDizinimi"},
    { img: img3, title: 'Biyoenerji', text: '', link:"/biyoenerji" },
    { img: img4, title: 'Meditasyon', text: '' , link:"/meditasyon"},
    { img: img5, title: 'Esma Terapisi', text: '', link:"/esmaTerapisi" },
    { img: img6, title: 'Regresyon', text: '' , link:"/regresyon"},
    { img: img7, title: 'Nlp', text: '' , link:"/nlp"},
    { img: img8, title: 'Teta Healing', text: '' , link:"/tetaHealing"},
    { img: img9, title: 'Access', text: '' , link:"/access"},
    { img: img10, title: 'Çocuk Terapisi', text: '' , link:"/cocukTerapisi"},
    { img: img11, title: 'Rüya Analizi', text: '' , link:"/ruyaAnalizi"},
    { img: img12, title: 'Kuantum NLP', text: '' , link:"/kuantumNlp"},
]

function Features(props) {
    return (
        <section className="feature-section section-gap grey-bg">
            <div className="container">
                <div className="section-title text-center both-border mb-50">
                    <span className="title-tag"> Hizmetler</span>
                   
                </div>
                {/* Feature boxes */}
                <div className="feature-boxes row justify-content-center">
                    {featuresblock.map((item, i) => (
                        <div key={i} className="col-lg-4 col-md-6 col-10 col-tiny-12">
                            <div className="feature-box">
                                <div className="feature-bg bg-img-c" style={{ backgroundImage: "url(" + item.img + ")" }}>
                                </div>
                                <div className="feature-desc">
                                    <Link to={item.link} className="feature-link"><i className="fal fa-long-arrow-right" />
                                    <h4>{item.title}</h4>
                                    <p>{item.text}</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Features;