import React, { Component, Fragment } from 'react';
import About from './About'

class Content extends Component {
    render() {
        return (
            <Fragment>
                <About />
            </Fragment>
        );
    }
}

export default Content;