import React from "react";
import illustration from "../../../assets/img/illustration/teta.jpg";

function Content(props) {
  return (
    <section className="about-section about-illustration-img section-gap">
      <div className="container">
        <div className="illustration-img" style={{marginTop:"10px"}}>
          <img style={{ borderRadius: "10px" }} src={illustration} alt="" />
        </div>
        <div className="row no-gutters justify-content-lg-end justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="about-text">
              <div className="section-title left-border mb-40">
                <span className="title-tag">Teta Healing</span>
                <h2 className="title">{/* Bağımsız Denetim <br />{" "} */}</h2>
              </div>
              <p
                className="mb-25"
                style={{ textIndent: "15px", textAlign: "justify" }}
              >
                Tetahealing tekniği, artık bize hizmet etmeyen ve ihtiyacımız
                olmayan blokajlarımızı anda dönüştürdüğümüz meditasyona dayalı
                bir yöntemdir. Geçmiş deneyimlerimizin bizde yarattığı olumsuz
                durumları, engelleri, blokajları hızlıca yapılandırır. Kendi
                istediğimiz hayatı kurabilmemize yardımcı olur.
              </p>
              <p style={{ textIndent: "15px", textAlign: "justify" }}>
                Konuşurken beyin dalgamız betada, meditasyon halindeyken alfada,
                rüya halindeyken ise tetadadır. Özel bir meditasyon ile beyin
                frekansımızı tetaya aldığımızda bize hizmet etmeyen blokajları
                olumlu program ile değiştirip hayatınızda pozitif değişimlere
                şahit olabilirsiniz.
              </p>{" "}
              <br></br>
              <p style={{ textIndent: "15px", textAlign: "justify" }}>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Content;
