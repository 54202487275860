import React, { Fragment } from 'react';
import NavHelper from '../../helper/NavHelper';
import { Link } from 'react-router-dom';
import Navmenu from './Navmenu';
import OffCanvas from './Offcanvas';

class Headertwo extends NavHelper {
    sendMail({ mailSubject, mailMessage }) {
        return (window.location.href =
          "mailto:" +
          "info@minelterapi.com" +
          "?subject=" +
          encodeURIComponent(mailSubject) +
          "&body=" +
          encodeURIComponent(mailMessage));
      }
    render() {
        const mobileactive = this.state.matches ? 'breakpoint-on' : '';
        const stickyheader = this.state.stickyheader ? 'sticky-on' : '';
        return (
            <Fragment>
                <header className={`header-two sticky-header ${stickyheader}`} style={{backgroundColor:"white"}} >
                    {/* Header Topbar */}
                    <div className="header-topbar">
                        <div className="container-fluid container-1600">
                            <div className="header-topbar-inner d-md-flex align-items-center justify-content-between">
                                {/* Contact Info */}
                                <ul className="contact-info">
                                    <li >
                                    <a
                                    style={{color:"#896933"}}
                        className=" contactIcon  "
                        target="_blank"
                        rel="noopener noreferrer"
                        href="tel:+905454545434"
                      >
                            <i className="far fa-phone text-primary" />
                            +90 545 454 54 34
                          </a>
                                    </li>
                                    <li>
                                    <span
                        className="contactIcon cursorPointer"
                        style={{cursor:"pointer",color:"#896933"}}
                        onClick={() =>
                          this.sendMail({
                            mailSubject: "Tanışma",
                            mailMessage:
                              "Merhaba Minel Terapi, \n \n Sizinle tanışmak istiyorum. \n Lütfen benimle iletişime geçiniz. \n \n Saygılarımla, \n",
                          })
                        }
                      >
                        <i className="far fa-envelope-open text-primary" />
                        info@minelterapi.com
                      </span>
                                    </li>
                                    <li>
                                    <a
                          href="https://www.google.com/maps/place/%C3%9Cmit,+2494.+Sk.+No:68,+06810+Yenimahalle%2FAnkara/@39.8912008,32.6996769,233m/data=!3m1!1e3!4m5!3m4!1s0x14d3388c19c5e3fb:0xb3e2402a20ccf7a0!8m2!3d39.8911863!4d32.7005332"
                          target="_blank"
                          rel="noopener noreferrer"
                          className=''
                          style={{color:"#896933"}}
                        >
                          <i className="far fa-map-marker-alt text-primary" /> Ümitköy/Ankara
                          </a>
                                    </li>
                                </ul>
                                {/* Social Links */}
                                <ul className="social-links">
                                    <li>
                                    <a
                       className="text-muted contactIcon"
                       target="_blank"
                       rel="noopener noreferrer"
                       href="https://instagram.com/minelterapi?igshid=YmMyMTA2M2Y="
                      ><i className="fab fa-facebook-f text-primary" /></a>
                                    </li>
                                    <li>
                                    <a
                        className="text-muted contactIcon "
                        target="_blank"
                        rel="noopener noreferrer"
                        href={
                          "https://wa.me/" +
                          "+905455035106" +
                          "?text=" +
                          "Ürünlerinizle%20ilgileniyorum.%20Lütfen%20benimle%20iletişime%20geçer%20misiniz?"
                        }
                      ><i className="fab fa-whatsapp text-primary" /></a>
                                    </li>
                                    <li>
                                    <a
                        className="text-muted contactIcon"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://instagram.com/minelterapi?igshid=YmMyMTA2M2Y="
                      ><i className="fab fa-instagram text-primary" /></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Header Menu  */}
                    <div className="header-nav sticky-nav">
                        <div className="container-fluid container-1600">
                            <div className={`nav-container ${mobileactive}`}>
                                {/* Site Logo */}
                                <div className="site-logo">
                                    <Link to="/"><img src={process.env.PUBLIC_URL + "/assets/img/minel.png"} alt="Logo" /></Link>
                                </div>
                                {/* Main Menu */}
                                <div className={this.state.togglemethod === true ? 'nav-menu d-lg-flex align-items-center menu-on' : 'nav-menu d-lg-flex align-items-center'}>
                                    {/* Navbar Close Icon */}
                                    <div className="navbar-close" onClick={this.toggleClass}>
                                        <div className="cross-wrap"><span /><span /></div>
                                    </div>
                                    {/* Mneu Items */}
                                    <div className="menu-items">
                                        <Navmenu />
                                    </div>
                                    {/* Pushed Item */}
                                    <div className="nav-pushed-item" />
                                </div>
                                {/* Navbar Extra  */}
                                <div className="navbar-extra d-flex align-items-center">
                                    {/* off canvas menu toggler*/}
                                    <div className="offcanvas-toggler" onClick={this.canvasToggle}>
                                        <span><span /><span /><span /></span>
                                    </div>
                                    {/* Navbar Toggler */}
                                    <div className={this.state.togglemethod === true ? 'navbar-toggler active' : 'navbar-toggler'} onClick={this.toggleClass}>
                                        <span /><span /><span />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className={this.state.classmethod === true ? 'offcanvas-wrapper show-offcanvas' : 'offcanvas-wrapper'}>
                    <div className={this.state.classmethod === true ? 'offcanvas-overly show-overly' : 'offcanvas-overly'} onClick={this.canvasToggle} />
                    <div className="offcanvas-widget">
                        <Link to="#" className="offcanvas-close" onClick={this.canvasToggle} ><i className="fal fa-times" /></Link>
                        <OffCanvas />
                    </div >
                </div >
            </Fragment>
        );
    }
}

export default Headertwo;