import React from "react";
import illustration from "../../../assets/img/illustration/esma.jpg";

function About(props) {
  return (
    <section className="about-section about-illustration-img section-gap">
      <div className="container">
        <div className="illustration-img">
          <img style={{ borderRadius: "10px" }} src={illustration} alt="" />
        </div>
        <div className="row no-gutters justify-content-lg-end justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="about-text">
              <div className="section-title left-border mb-40">
                <span className="title-tag">Esma Terapisi</span>
                {/* <h2 className="title">
                  Esma Terapisi
                  <br />{" "}
                </h2> */}
              </div>
              <p
                className="mb-25"
                style={{  textAlign: "justify" }}
              >
                Bunlar ne güzel söz kalpte bestedir <br/>
                Ölüm hırka hırka dilde bestedir <br/>
                Bu köprüyü kim geçerse o ustadır <br/><br/>
                Yaşamınıza sakinlik katmak istiyorsanız Esmalarla Minel Terapi
              </p>
              <p
                className="mb-25"
                style={{  textAlign: "justify" }}
              >
                Bunlar ne güzel söz kalpte bestedir <br/>
                Ölüm hırka hırka dilde bestedir <br/>
                Bu köprüyü kim geçerse o ustadır <br/><br/>
                Yaşamınıza sakinlik katmak istiyorsanız Esmalarla Minel Terapi
              </p>
              <p
                className="mb-25"
                style={{  textAlign: "justify" }}
              >
                Bunlar ne güzel söz kalpte bestedir <br/>
                Ölüm hırka hırka dilde bestedir <br/>
                Bu köprüyü kim geçerse o ustadır <br/><br/>
                Yaşamınıza sakinlik katmak istiyorsanız Esmalarla Minel Terapi
              </p>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default About;
