import React, { Component, Fragment } from 'react';

import logo from '../../assets/img/minel.png'

class Offcanvas extends Component {
  sendMail({ mailSubject, mailMessage }) {
    return (window.location.href =
      "mailto:" +
      "info@minelterapi.com" +
      "?subject=" +
      encodeURIComponent(mailSubject) +
      "&body=" +
      encodeURIComponent(mailMessage));
  }
    render() {
        return (
            <Fragment>
                {/* About Widget */}
                <div className="widget about-widget">
                    <div className="logo mb-30">
                        <img src={logo} alt="" />
                    </div>
                    <p>
                    Yücelir makamım edemem tarif, anlarsan halimden olursun Arif
                    </p>
                </div>
                {/* Contact information Widget */}
                <div className="widget contact-info-widget">
                    <h5 className="widget-title">Bizimle İletişime Geçin</h5>
                    <ul>
                        <li>
                        <span className="text-muted contactIcon">
                        <a
                          href="https://www.google.com/maps/place/%C3%9Cmit,+2494.+Sk.+No:68,+06810+Yenimahalle%2FAnkara/@39.8912008,32.6996769,233m/data=!3m1!1e3!4m5!3m4!1s0x14d3388c19c5e3fb:0xb3e2402a20ccf7a0!8m2!3d39.8911863!4d32.7005332"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="far fa-map-marker-alt text-primary" />
                            Ümitköy/Ankara
                        </a>
                      </span>
                        </li>
                        <li>
                            <a
                        className="text-muted contactIcon  "
                        target="_blank"
                        rel="noopener noreferrer"
                        href="tel:+905454545434"
                      >
                        <i className="far fa-phone text-primary" />
                        +90 545 454 54 34
                      </a>
                      
                        </li>
                        <li>
                        <span
                        className="text-muted contactIcon cursorPointer"
                        style={{cursor:"pointer"}}
                        onClick={() =>
                          this.sendMail({
                            mailSubject: "Tanışma",
                            mailMessage:
                              "Merhaba Minel Terapi, \n \n Sizinle tanışmak istiyorum. \n Lütfen benimle iletişime geçiniz. \n \n Saygılarımla, \n",
                          })
                        }
                      >
                        <i className="far fa-envelope-open text-primary" />
                        info@minelterapi.com
                      </span>
                        </li>
                    </ul>
                </div>
                {/* Social Link */}
                <div className="widget social-link">
                    <h5 className="widget-title">Bizi Takip Edin</h5>
                    <ul>
                        <li> <a
                        className="text-muted contactIcon"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://instagram.com/minelterapi?igshid=YmMyMTA2M2Y="
                      ><i className="fab fa-facebook-f" /></a></li>
                        <li> <a
                        className="text-muted contactIcon"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://instagram.com/minelterapi?igshid=YmMyMTA2M2Y="
                      ><i className="fab fa-instagram" /></a></li>
                    </ul>
                </div>
            </Fragment>
        );
    }
}

export default Offcanvas;