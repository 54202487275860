import React, { Component, Fragment } from 'react'
import Teamblock from './Teamblock'

class Content extends Component {
    render() {

    return (
        <Fragment>
            <Teamblock />
        </Fragment>
        );
    }
}

export default Content;