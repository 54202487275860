import React from "react";
import illustration from "../../../assets/img/illustration/ruya.jpg";

function Workingprocess(props) {
  return (
    <section className="about-section about-illustration-img section-gap">
      <div className="container">
        <div className="illustration-img" style={{marginTop:"20px"}}>
          <img style={{ borderRadius: "10px" }} src={illustration} alt="" />
        </div>
        <div className="row no-gutters justify-content-lg-end justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="about-text">
              <div className="section-title left-border mb-40">
                <span className="title-tag">Rüya Analizi</span>
                {/* <h2 className="title">
                  KGF ve Ticari Kredi Finansmanı
                  <br />{" "}
                </h2> */}
              </div>
              <p
                className="mb-25"
                style={{ textIndent: "15px", textAlign: "justify" }}
              >
                Rüya ve rüya analizi özelikle danışanlar tarafından çok merak edilen kavramlardır. Gördüğünüz rüyanın anlamı nedir, rüyada görülen semboller ne işe yarıyor, bunların anlamları nelerdir soruları sık sık aklınıza gelmektedir. Rüyalar bu yüzden insanlığın ilk günlerinden beri merak edilen kavramlar haline gelmiştir. Psikoloji dünyası ise rüya analizi ile 1900’lü yıllarda ilgilenmeye başlamıştır. Son zamanlarda ise psikanaliz ve psikoterapide rüyaların anlamları da çalışılmaktadır. Terapilerde rüyanın yorumlanması, rüya analizi önem kazanmaktadır. Psikoterapide rüya analizini ilk kullanan kuramcı Sigmund Freud’dur. Freud, rüya görmemizin bir anlamı olduğunu ifade etmiştir. Kişinin gün içerisinde kendisine söylemek istemediği yaşantıları, geçmiş hayatındaki yaşantıları rüya yoluyla gün yüzüne çıkardığını belirtmektedir.
              </p>

              <p style={{ textIndent: "15px", textAlign: "justify" }}>


              </p>
              <br></br>
             
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Workingprocess;
