import React, { Component, Fragment } from 'react'
import Banner from './Banner'
import Service from './Service'

class Content extends Component {
    render() {
        return (
            <Fragment>
                <Banner />
                <Service />
            </Fragment>
        );
    }
}

export default Content;