import React from "react";
import illustration from "../../../assets/img/illustration/reg.jpeg";

function Content(props) {
  return (
    <section className="about-section about-illustration-img section-gap">
      <div className="container">
        <div className="illustration-img">
          <img style={{ borderRadius: "10px" }} src={illustration} alt="" />
        </div>
        <div className="row no-gutters justify-content-lg-end justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="about-text">
              <div className="section-title left-border mb-40">
                <span className="title-tag">Regresyon</span>
                <h2 className="title">
                  {/* Konkordato İşlemleri <br />{" "} */}
                </h2>
              </div>
              <p
                className="mb-25"
                style={{ textIndent: "15px", textAlign: "justify" }}
              >
                Regresyon terapisi, fiziksel, ruhsal veya duygusal sorunların
                kökeninin, herkesin geçmişinde, özellikle çocukluk döneminde
                yaşadığı olumsuz olaylardan, istismar, taciz gibi fiziksel veya
                ruhsal travmalardan kaynaklandığını varsayan ve herhangi bir
                çağrışım yoluyla bu olayların kişinin zihninde yeniden
                canlandırılması amacıyla yapılan bir psikoterapi tekniğidir.
              </p>
              <br></br>
              <p style={{ textIndent: "15px", textAlign: "justify" }}>
                Regresyon terapisi basit hatırlamadan biraz daha farklı bir
                uygulamadır. Basit hatırlama zaten bildiğimiz olayların yeniden
                aklımıza gelmesi olarak tanımlanırken, regresyon normalde
                kişinin hatırlamadığı olayların çağrışım ve telkin yoluyla
                ortaya çıkarılmasıdır.
              </p>
              <br></br>
              <br></br>
              <p style={{ textIndent: "15px", textAlign: "justify" }}>
                Regresyon terapisinde amaç soruna neden olan ilk olayı açığa
                çıkarmaktır. Bu ne kadar basit de olsa, etkisi zayıf gibi
                görünse de sorunu başlatan olaydır
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Content;
