import React from "react";
import illustration from "../../../assets/img/illustration/aile.jpg";

function Content(props) {
  return (
    <section className="about-section about-illustration-img section-gap">
      <div className="container">
      <div className="illustration-img">
          <img style={{ borderRadius: "10px" }} src={illustration} alt="" />
          <br></br>

         
        </div>
        <div className="row no-gutters justify-content-lg-end justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="about-text">
              <div className="section-title left-border mb-40">
                <span className="title-tag">Aile Dizimi</span>
               
              </div>

              <p style={{ textIndent: "15px", textAlign: "justify" }}>
                Bert Helliger’ın geliştirdiği aile dizimi terapisi, bireylerin
                aileleriyle içsel dünyalarında barışmalarını sağlamayı ve aile
                sisteminin bozulmuş yanlarını onarmayı hedeflemektedir. Bunun
                yanında aile bireylerin yaşının, cinsiyetlerinin, yaşayıp
                yaşamadığının, bu bireylerin nerede ve hangi zaman diliminde
                yaşadığının bir önemi yoktur. Aile diziminin yapılması, terapi
                sürecinin olmazsa olmaz parçasıdır. 
                


                <p style={{ textIndent: "15px", textAlign: "justify" }}>Bu dizilimi yapabilmek için
                aile sisteminde dışlanan (olumsuz olaylara sebep olan) kişinin
                yerinin kendisine geri verilmesi gerekir. Bu geri verme
                genellikle bir sandalye ile görsel şeklini bulmaktadır. Aile
                dizimi yapılan bireyin karmaşık aile bağları ortaya çıkarılır ve
                kişi ailede kendini kiminle özdeşleştiriyor ise bunun farkına
                varır. Bireylerin kendilerini özdeşleştirdikleri kişiden farklı
                bir birey olduklarını fark etmeleri için şan verilir. Bireyler,
                özdeşleştikleri kişi ile aralarındaki farkı keşfedemezlerse,
                başarılı olana kadar çalışma devam eder. Bireyin ailesinde
                önceden yaşamış nesiller ile arasında bağ kurabilmesi ve karışık
                olan bağları çözebilmesi için bir aracı bulunur</p>
                
              </p>

            
              
            </div>
          </div>
        </div>
       
      </div>
    </section>
  );
}
export default Content;
