import React, { Component } from 'react';
import { Link } from 'react-router-dom'

const navigationmenu = [
    {
        id: 1,
        linkText: 'Ana Sayfa',
        link: '/'
    },
    {
        id: 2,
        linkText: 'Hizmetlerimiz',
        link: '/hizmetler',
        child: true,
        submenu: [
            {
                id: 21,
                link: '/yasamKoclugu',
                linkText: 'Yaşam Koçluğu'
            },
            {
                id: 22,
                link: '/aileDizinimi',
                linkText: 'Aile Dizimi'
            },
            {
                id: 23,
                link: '/biyoenerji',
                linkText: 'Biyoenerji'
            },
            {
                id: 24,
                link: '/meditasyon',
                linkText: 'Meditasyon'
            },
            {
                id: 25,
                link: '/esmaTerapisi',
                linkText: 'Esma Terapisi'
            },
            {
                id: 26,
                link: '/regresyon',
                linkText: 'Regresyon'
            },
            {
                id: 27,
                link: '/nlp',
                linkText: 'NLP'
            },
            {
                id: 28,
                link: '/tetaHealing',
                linkText: 'Teta Healing'
            },
            {
                id: 29,
                link: '/access',
                linkText: 'Access'
            },
            {
                id: 291,
                link: '/kuantumNlp',
                linkText: 'Kuantum NLP'
            },
            {
                id: 292,
                link: '/cocukTerapisi',
                linkText: 'Çocuk Terapisi'
            },
            {
                id: 293,
                link: '/ruyaAnalizi',
                linkText: 'Rüya Analizi'
            },
        ]
    },
    // {
    //     id: 3,
    //     linkText: 'Hizmetlerimiz',
    //     link: '/mental',
    //     child: true,
    //     submenu: [
    //         {
    //             id: 31,
    //             link: '/bir',
    //             linkText: 'bir'
    //         },
    //         {
    //             id: 32,
    //             link: '/iki',
    //             linkText: 'iki'
    //         },
    //         {
    //             id: 33,
    //             link: '/uc',
    //             linkText: 'uc'
    //         },
    //         {
    //             id: 34,
    //             link: '/dort',
    //             linkText: 'dort'
    //         },
    //         {
    //             id: 35,
    //             link: '/bes',
    //             linkText: 'bes'
    //         },
    //         {
    //             id: 36,
    //             link: '/alti',
    //             linkText: 'alti'
    //         },
    //         {
    //             id: 37,
    //             link: '/yedi',
    //             linkText: 'yedi'
    //         },
    //         {
    //             id: 38,
    //             link: '/sekiz',
    //             linkText: 'sekiz'
    //         },
    //     ]
    // },
    {
        id: 4,
        linkText: 'Hakkımızda',
        link: '/hakkimizda'
    },
    // {
    //     id: 5,
    //     linkText: 'Sıkça Sorulan Sorular',
    //     link: '/sikcaSorulanSorular'
    // },
    {
        id: 7,
        linkText: 'İletişim',
        link: '/iletisim'
    },
]

class Navmenu extends Component {
    render() {
        const getNextSibling = (elem, selector) => {
            // Get the next sibling element
            var sibling = elem.nextElementSibling
            // If there's no selector, return the first sibling
            if (!selector) return sibling
            // If the sibling matches our selector, use it
            // If not, jump to the next sibling and continue the loop
            while (sibling) {
                if (sibling.matches(selector)) return sibling
                sibling = sibling.nextElementSibling
            }
        }

        const triggerChild = (e) => {
            let subMenu = ''
            subMenu = (getNextSibling(e.target, '.submenu') !== undefined) ? getNextSibling(e.target, '.submenu') : null
            if (subMenu !== null && subMenu !== undefined && subMenu !== '') {
                subMenu.classList = subMenu.classList.contains('d-block') ? 'submenu' : 'submenu d-block'
            }
        }
        return (
            <ul>
                {navigationmenu.length > 0 ? navigationmenu.map((item, i) => (
                    <li key={i} className={`${item.child ? 'has-submemu' : ''} `} onClick={triggerChild}>
                        {item.child ? <> <Link  to={item.link}> {item.linkText} </Link> <span className="dd-trigger"><i className="fal fa-angle-down"></i></span> </> : <Link to={item.link}> {item.linkText} </Link>}
                        {item.child ?
                            <ul className="submenu" role="menu">
                                {item.submenu.map((sub_item, i) => (
                                    <li key={i} className={`${sub_item.child ? 'has-submemu' : ''} `}>
                                        {sub_item.child ? <> <Link onClick={e => e.preventDefault()} to="/"> {sub_item.linkText} </Link> <span className="dd-trigger"><i className="fal fa-angle-down"></i></span> </> : <Link to={sub_item.link}> {sub_item.linkText} </Link>}
                                        {sub_item.submenu ?
                                            <ul className="submenu">
                                                {sub_item.submenu.map((third_item, i) => (
                                                    <li key={i}><Link
                                                        to={third_item.link}>{third_item.linkText}</Link>
                                                    </li>
                                                ))}
                                            </ul> : null}
                                    </li>
                                ))}
                            </ul>
                            : null
                        }
                    </li>
                )) : null}
            </ul>
        );
    }
}

export default Navmenu;