import React from "react";
import illustration from "../../../assets/img/illustration/nlp.jpg";

function Content(props) {
  return (
    <section className="about-section about-illustration-img section-gap">
      <div className="container">
        <div className="illustration-img">
          <img style={{ borderRadius: "10px" }} src={illustration} alt="" />
        </div>
        <div className="row no-gutters justify-content-lg-end justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="about-text">
              <div className="section-title left-border mb-40">
                <span className="title-tag">Nlp</span>
                <h2 className="title">
                  {/* Devlet ve Bakanlık Destekleri <br />{" "} */}
                </h2>
              </div>
              <p
                className="mb-25"
                style={{ textIndent: "15px", textAlign: "justify" }}
              >
                NLP (Neuro Linguistic Programming), ülkemizde, Sinir Dili
                Programlaması olarak adlandırılır. Zihni kontrol altına alma
                yöntemi olarak bilinen tüm problemlerin üstesinden gelebilecek
                bir uygulamadır. Kişisel beceri ile yeteneklerin ortaya
                çıkarılması ve pekiştirilmesinde kullanılan NLP, algılama,
                duygu, düşünme ve davranış süreçlerini bilinçli hale getiren,
                yapıcı bir şekilde geliştirmede kullanılan bir dizi yöntem
                içerir. NLP son derece basit bir programlama olup hemen
                uygulanabilecek çok etkili teknikler içeren bir yöntemdir. Bu
                teknikler Sibernetik, Psikoloji, Filoloji ve Nöroloji gibi
                bilimlerin ışığında geliştirilmiştir. Ayrıca bu teknikler
                eğitim, sağlık, aile, yönetim, satış ve spor bilimlerinde de
                başarılı bir şekilde kullanılmaktadır.
              </p>
              <p style={{ textIndent: "15px", textAlign: "justify" }}>
                İnsanların çevrelerini algılama ve gösterdikleri tepkiler,
                iletişim şekilleri ve davranış kalıpları üzerinde yapılan
                araştırmalar NLP'nin altyapısını oluşturur. Bu araştırmalar
                sonucunda elde edilen ve geliştirilen teknik ve yöntemler,
                insanların iletişimini geliştirmede kullanılmakla beraber çözüm
                ve hedef bulma süreçlerinde de başarılı bir şekilde
                kullanılmaktadır.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Content;
