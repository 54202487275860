import React, { Component, Fragment } from 'react'
import Features from './Features'

class Content extends Component {
    render() {

        return (
            <Fragment>
                <Features />
            </Fragment>
        );
    }
}

export default Content;