import React from "react";

import illustration from "../../assets/img/illustration/hakkimizda.jpg";

function About(props) {
  return (
    <section className="about-section about-illustration-img section-gap">
      <div className="container">
        <div className="illustration-img">
          <img
            style={{ borderRadius: "10px", marginTop: "40px" }}
            src={illustration}
            alt=""
          />
        </div>
        <div className="row no-gutters justify-content-lg-end justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="about-text">
              <div className="section-title left-border mb-40">
                <span className="title-tag">Hakkımızda</span>
                <h2 className="title">
                  Minel Terapi
                  <br />{" "}
                </h2>
              </div>
              <p
                className="mb-25"
                style={{ textIndent: "15px", textAlign: "justify" }}
              >
                Minel Terapi olarak Biyoenerji, Reglasyon, Aile Dizinimi, Sufi
                Terapi, Meditasyon, NLP, Yaşam Koçluğu hizmetleri ve bunun yanı sıra e-devlet onaylı sertifikalar vermekteyiz.
              </p>
              <p style={{ textIndent: "15px", textAlign: "justify" }}>
                ...
              </p>{" "}
              <br></br>
              <p style={{ textIndent: "15px", textAlign: "justify" }}>
                Bütün evrenin enerji akışıyla hareket ettiğini unutmayalım. Bunu
                ilim ve imanla birleştirdiğimiz an hayata dair bütün sıkıntıları
                çözmemiz mümkündür. Eğer şifa bulmak ve yenilenmek isterseniz Minel Terapi olarak hizmetinizdeyiz.
              </p>
              <p style={{ textIndent: "15px", textAlign: "justify" }}>
                Bütün evrenin enerji akışıyla hareket ettiğini unutmayalım. Bunu
                ilim ve imanla birleştirdiğimiz an hayata dair bütün sıkıntıları
                çözmemiz mümkündür. Eğer şifa bulmak ve yenilenmek isterseniz Minel Terapi olarak hizmetinizdeyiz.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default About;
