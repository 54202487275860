import React from "react";
import illustration from "../../../assets/img/illustration/access.jpeg";

function Content(props) {
  return (
    <section className="about-section about-illustration-img section-gap">
      <div className="container">
        <div className="illustration-img" style={{marginTop:"50px"}}>
          <img style={{ borderRadius: "10px" }} src={illustration} alt="" />
        </div>
        <div className="row no-gutters justify-content-lg-end justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="about-text">
              <div className="section-title left-border mb-40">
                <span className="title-tag">Access</span>
                <h2 className="title">{/* Bağımsız Denetim <br />{" "} */}</h2>
              </div>
              <p
                className="mb-25"
                style={{ textIndent: "15px", textAlign: "justify" }}
              >
                Başın üzerinde yer alan 32 özel noktaya uygulanan bir enerji
                seansıdır. İnsanların farklı şekilde işlev yapmalarına,
                değişimlerine ve hayatlarında olasılıklara açık olmalarına
                yardım eden bir bilinçaltı temizliği enerji çalışmasıdır.
              </p>
              <p style={{ textIndent: "15px", textAlign: "justify" }}>
                Bedenimizle bağlantılı bu 32 nokta, herhangi bir zamanda
                yarattığımız veya aldığımız; düşünce, fikir, inanç ve görüşleri
                saklar. Bu noktalar; sözel proseslerle ele aldığımız, kaynak
                olarak tanımladığımız bilgilere dayalıdır. Bu nedenle barsların
                çalıştırılması, dinamik bir ihtiyaçtır.
              </p>{" "}
              <br></br>
              <p style={{ textIndent: "15px", textAlign: "justify" }}>
                Bars noktaları; bedeninizle doğrudan bağlantılıdır. Barsların
                çalışmaya başlamasını takip eden zaman içinde; düşüncelerin,
                duyguların, hislerin ve enerjilerin hücreler üzerinde yarattığı
                etkileri de serbest bırakır.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Content;
