import React from "react";
import illustration from "../../../assets/img/illustration/bio.jpg";

function Blocks(props) {
  return (
    <section className="about-section about-illustration-img section-gap">
      <div className="container">
        <div className="illustration-img" style={{marginTop:"40px"}}>
          <img style={{ borderRadius: "10px" }} src={illustration} alt="" />
        </div>
        <div className="row no-gutters justify-content-lg-end justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="about-text">
              <div className="section-title left-border mb-40">
                <span className="title-tag">Biyoenerji</span>
                <h2 className="title">{/* SGK Teşvikleri <br />{" "} */}</h2>
              </div>
              <p className="mb-25" style={{ textAlign: "justify" }}>
                Yücelir makamım edemem tarif <br></br>
                Anlarsan sözümden olursun Arif <br></br>
                Allahın isminde keremim vardır <br></br>
                Elbet benimde münezzehte bir görenim vardır <br></br>
                Bu dünya boştur amma <br></br>
                Benimde bu dünyada bir muradım vardır <br/> <br/>
                Biyoenerji kelimesinin
                anlamı kendisini oluşturan kelimelerin içinde saklıdır. Biyo;
                hayat, canlı veya yaşayan varlıklar; enerji ise iş yapma
                yeteneğine sahip olmaktır. Eğer bir madde kendinde var olan güç
                ile bir şeyi harekete geçirebiliyorsa, buna enerji kelimesi
                atanır.
              </p>

              <p
                className="mb-25"
                style={{ textIndent: "15px", textAlign: "justify" }}
              >
                DNA'daki 12 sarmalda bulunan sırrı çözmek için Minel Terapi
                olarak hizmetinize hazırız.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Blocks;
