import React, { Component, Fragment } from 'react';
import Header from '../layouts/Headertwo'
import Footer from '../layouts/Footer'
import Content from './Content'

class About extends Component {
    render() {
        return (
            <Fragment>
                <Header />
                <Content />
                <Footer />
            </Fragment>
        );
    }
}

export default About;