import React, { Component, Fragment } from "react";
import Header from "../layouts/Headertwo";
import Footer from "../layouts/Footer";
import Content from "./Content";

class Contact extends Component {
  render() {
    return (
      <Fragment>
        <Header />
        <Content />
        <section className="client-section">
          <div className="container">
            <div className="client-slider section-gap line-top"></div>
          </div>
        </section>
        <Footer />
      </Fragment>
    );
  }
}

export default Contact;
