import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap'

function Faqs(props) {
    return (
        <section className="faq-section section-gap">
            <div className="container">
                {/* FAQ Page Title */}
                <div className="row faq-page-title mb-60 align-items-center">
                    <div className="col-lg-6">
                        <div className="section-title left-border">
                            <span className="title-tag">SSS</span>
                            <h2 className="title">Sıkça Sorulan Sorular</h2>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <p>
                            Daha fazla açıklama,burada bulamadığınız sorular ve özel sorularınız için lütfen bizimle iletişime geçin.
                            </p>
                    </div>
                </div>
                {/* FAQ LOOP */}
                <Accordion defaultActiveKey="0" className="faq-loop grey-header" id="faqAccordion">
                    <Card>
                        <Accordion.Collapse eventKey="0" className="collapseparent">
                            <Card.Body>
                               Firmanıza ait bir kaç temel belge ile ortalama 1 hafta süre içerisinde gerekli araştırmaları yaparak tarafınıza olumlu ya da olumsuz dönüş sağlıyoruz.
                                </Card.Body>
                        </Accordion.Collapse>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                Firmam için ticari kredi kullanabilir miyim ?
                                    <span className="icons">
                                    <i className="far fa-plus" />
                                </span>
                            </Accordion.Toggle>
                        </Card.Header>
                    </Card>
                    <Card>
                        <Accordion.Collapse eventKey="1" className="collapseparent">
                            <Card.Body>
                               Gerekli araştırmalar yapıldıktan sonra kredibilte durumunuza göre 20-60 gün içerisinde gerekli finansmanı elde etmenizi sağlıyoruz.
                                </Card.Body>
                        </Accordion.Collapse>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                Finansman elde etmek ne kadar sürer ?
                                    <span className="icons">
                                    <i className="far fa-plus" />
                                </span>
                            </Accordion.Toggle>
                        </Card.Header>
                    </Card>
                    <Card>
                        <Accordion.Collapse eventKey="2" className="collapseparent">
                            <Card.Body>
                              Firmanızın bulunduğu ile ve faaliyet konularınıza göre desteklerden faydalanabilirsiniz.
                                </Card.Body>
                        </Accordion.Collapse>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="2">
                              İmalat firması sahibiyim, Yatırım Teşvik Belgesi alabilir miyim ?
                                    <span className="icons">
                                    <i className="far fa-plus" />
                                </span>
                            </Accordion.Toggle>
                        </Card.Header>
                    </Card>
                    <Card>
                        <Accordion.Collapse eventKey="3" className="collapseparent">
                            <Card.Body>
                                Firmanızda çalışan personelinize yönelik inleme neticesinde hangi personelin hangi teşvikten faydalanabileceğini tarafınıza bildiriyoruz.
                                </Card.Body>
                        </Accordion.Collapse>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="3">
                               SGK teşviklerinden faydalanabilmem için ne yapmam gerekiyor ?
                                    <span className="icons">
                                    <i className="far fa-plus" />
                                </span>
                            </Accordion.Toggle>
                        </Card.Header>
                    </Card>
                    <Card>
                        <Accordion.Collapse eventKey="4" className="collapseparent">
                            <Card.Body>
                           Bu destekler faaliyet konusuna göre ve bulunduğunuz ile göre değişiklik göstermektedir. Ayrıntılı bilgi için bizimle iletişime geçebilirsiniz.
                                </Card.Body>
                        </Accordion.Collapse>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="4">
                               Firmamın hangi devlet desteklerinden faydalanabileceğini nasıl öğrenebilirim ?
                                    <span className="icons">
                                    <i className="far fa-plus" />
                                </span>
                            </Accordion.Toggle>
                        </Card.Header>
                    </Card>
                  
                </Accordion>
                {/* End Faq LOOP */}
            </div>
        </section>
    );
}

export default Faqs;